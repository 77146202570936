// @forward "animation";
// @forward "images";
// @use "images" as images;
// [ ] 画像URLの変数化を復活？

$black: #131313;
$white: #fefefe;
$gray01: #1a1a1a;
$gray02: #333333;
$gray03: #808080;
$gray04: #cccccc;
$gray05: #f2f2f2;
$colorDecide: #d94343;
$colorCleared: #fcd021;
$colorBlue01: #1b1464;
$colorBlue02: #7fcdee;
$colorRed01: #740c10;
$colorYellow01: #fbb03b;
$colorPink: #ffb0cd;
$colorRgbaBlack01: rgba(0, 0, 0, 0.8);
$colorRgbawhite01: rgba(255, 255, 255, 0.5);

$length16: 4.2666666666667%;
$length21: 5.6%;
$length150: 40%;

$breakpoint-down: (
  "xs": "screen and (max-width: 480px)",
  "sm": "screen and (max-width: 768px)",
  "md": "screen and (max-width: 960px)",
  "lg": "screen and (max-width: 1024px)",
  "xl": "screen and (max-width: 1200px)",
) !default;

//スクロールバー
@mixin scrollbar {
  overflow: auto;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: $gray05;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $gray03;
    border: solid 3px $gray05;
  }
}

//フレーム
@mixin frameA {
  padding: calc($length16 * 2);
  background-image: images.$frameAbg;
  background-size: 30px auto, 30px auto, 30px auto, 30px auto,
    calc(100% - 36px * 2) 1px, calc(100% - 36px * 2) 1px,
    1px calc(100% - 30px * 2), 1px calc(100% - 30px * 2);
  background-position: top 8px left 8px, top 8px right 8px, bottom 8px left 8px,
    bottom 8px right 8px, top 12px center, bottom 12px center, center left 13px,
    center right 13px;
  background-repeat: no-repeat;
}

@mixin frameModal {
  padding: calc($length16 * 2);
  background-image: images.$modalInnerFrame;
  background-size: 15% auto;
  background-position: top 8px left 8px, top 8px right 8px, bottom 8px left 8px,
    bottom 8px right 8px;
  background-repeat: no-repeat;
}

//ボタン
//クローズボタンA
@mixin btnCloseA {
  position: absolute;
  top: 0;
  right: 0;
  background-color: $white;
  border: solid 2px $black;
  border-radius: 100%;
  transform: translate(-20%, 20%);
}
//通常ボタン（ロングサイズ）
@mixin btnNormalFull {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 44px;
  color: $white;
  background-image: images.$btnNormalBgBlueLng;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
//通常ボタン（ハーフサイズ）
@mixin btnNormalHalf {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc((100% - $length16) / 2);
  height: 60px;
  background-image: images.$btnNormalBgBlue;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

@keyframes buttonKirakira {
  0% {
    background-position: 100% 50%;
  }
  33% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@mixin buttonKirakira {
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 40%,
    rgba(255, 255, 255, 0.6) 50%,
    rgba(255, 255, 255, 0) 60%,
    rgba(255, 255, 255, 0) 100%
  );
  background-size: 400% 400%;
  animation: buttonKirakira 1.5s ease infinite;
}

@mixin textShadow($color: $black) {
  text-shadow: 1px 1px 1px $color, -1px -1px 1px $color, -1px 1px 1px $color,
    1px -1px 1px $color;
}
