@use "variable" as var;

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap");

html,
body {
  padding: 0;
  margin: 0;
  line-height: 1.5;
  // font-family: "Noto Sans JP", sans-serif;
  font-family: "M PLUS Rounded 1c", "游ゴシック体", "Yu Gothic", YuGothic,
    Meiryo, "メイリオ", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro",
    "ＭＳ Ｐゴシック", "MS PGothic", arial, sans-serif;
  background-image: url("/images/bg/bg_ptn01.jpg");
  background-size: 65px auto;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/*ルビ系*/
.furigana {
  line-height: 1.7;
}
ruby[data-ruby] {
  position: relative;
  display: inline-block;
  &::before {
    content: attr(data-ruby);
    position: absolute;
    top: 0;
    left: -3em;
    right: -3em;
    font-size: 1em;
    line-height: 0.2;
    text-align: center;
    transform: scale(0.4);
  }
  rt {
    display: none;
  }
}
.mainBody {
  padding: var.$length16 var.$length16 calc(var.$length16 * 4);
}
.pageHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  font-size: 20px;
  font-weight: bold;
  color: var.$white;
  background-color: var.$gray04;
  background-image: url("/images/frame/frame_header01.png");
  background-size: 90% auto;
  background-position: center center;
  background-repeat: no-repeat;
  &.profile {
    background-color: var.$colorBlue01;
  }
  &.test {
    background-color: var.$colorRed01;
  }
  &.riddle {
    color: var.$black;
    background-image: url("/images/frame/frame_header02.png");
    background-color: var.$colorYellow01;
    &.single {
      font-size: 16px;
      text-align: center;
    }
  }
}
.btnDecide {
  display: block;
  width: 104px;
  height: 46px;
  margin: 16px auto 0;
  font-size: 14px;
  font-weight: bold;
  color: var.$white;
  background-image: url("/images/bg/bg_btn_red_s01.png");
  background-size: 100% 100%;
  @include var.textShadow;
}
.btnStart {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 240px;
  height: 40px;
  margin: 0 auto;
  font-size: 16px;
  font-weight: bold;
  background-color: var.$colorYellow01;
}
.messageBox {
  position: relative;
  display: block;
  padding: 16px;
  margin: 16px 0 32px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.5;
  text-align: justify;
  background-color: var.$white;
  &.center {
    text-align: center;
  }
  &::before,
  &::after {
    content: "";
    position: absolute;
    display: block;
    width: 120px;
    height: 20px;
    background-size: 100% 100%;
  }
  &::before {
    top: 5px;
    left: -6px;
    background-image: url("/images/deco/deco_message01_1.png");
    transform: translateY(-100%);
  }
  &::after {
    bottom: 5px;
    right: -6px;
    background-image: url("/images/deco/deco_message01_2.png");
    transform: translateY(100%);
  }
}
.caution {
  margin-top: 16px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
}
